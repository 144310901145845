<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont">
            <div class="work-order-detail">
                <div class="work-order-detail_l">
                    <div class="l_hd">
                        <h2>单据ID：{{ record.ctripEventId }}</h2>
                        <el-tag size="mini"
                                class="ml10"
                                effect="plain"
                                :type="record.punishOrderStatus | formatDesc(punishOrderStatusOptions,'value','type')">
                            单据状态： {{ record.punishOrderStatus | formatDesc(punishOrderStatusOptions) }}
                        </el-tag>
                        <el-tag class="ml10" size="mini" effect="plain"
                                :type="record.appealStatus | formatDesc(appealStatusOptions,'value','type')">
                            申诉状态：{{ record.appealStatus | formatDesc(appealStatusOptions) }}
                        </el-tag>
                    </div>
                    <!--                    <div class="l_remark">-->
                    <!--                        违规备注：{{ record.tips }}-->
                    <!--                    </div>-->
                    <el-descriptions :column="3" size="mini">
                        <el-descriptions-item label="业务线">用车</el-descriptions-item>
                        <el-descriptions-item label="违规原因">
                            {{ record.firstfoulReasonName }}-{{ record.secondFoulReasonName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="剩余申诉次数">
                            {{ record.maxAppealTime }}
                        </el-descriptions-item>
                        <el-descriptions-item label="当次申诉截至时间">{{ record.deadLine }}</el-descriptions-item>
                        <el-descriptions-item label="单据创建时间">{{ record.punishCreateTime | dateCus }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-divider/>
                    <h3>明细信息</h3>
                    <el-table border size="mini" :data="record.punishContentDetail">
                        <el-table-column label="惩罚对象类型" prop="punishObjectType">
                            <template>
                                {{ record.punishObjectType | formatDesc(punishObjectTypeOptions) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="惩罚对象id" prop="punishTargetId">
                            <template>
                                {{ record.punishTargetId }}
                            </template>
                        </el-table-column>
                        <el-table-column label="惩罚方式" prop="punishWay">
                            <template slot-scope="scope">
                                {{ scope.row.punishWay | formatDesc(punishWayOptions) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="惩罚力度" prop="punishWeight">
                            <template slot-scope="scope">
                                {{ scope.row.punishWeight }}{{ scope.row.punishWeightUnit }}
                            </template>
                        </el-table-column>
                        <el-table-column label="惩罚状态">
                            <template>
                                <el-tag size="mini"
                                        effect="plain"
                                        :type="record.appealStatus | formatDesc(appealStatusOptions,'value','type')">
                                    {{ record.appealStatus | formatDesc(appealStatusOptions) }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="结算状态">
                            <template>
                                {{ record.punishOrderStatus  | formatDesc(punishOrderStatusOptions) }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <h3>补充信息</h3>
                    <el-table border :data="record.others" size="mini">
                        <el-table-column label="类型" prop="name"></el-table-column>
                        <el-table-column label="内容" prop="content">
                            <template slot-scope="scope">
                                <el-link style="font-size: 12px" type="primary" v-if="scope.row.name==='订单号'"
                                         :href="'#/userCarList?orderNo='+scope.row.content">
                                    {{ scope.row.content }}
                                </el-link>
                                <span v-else>{{ scope.row.content }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">--</el-table-column>
                    </el-table>
                    <h3>申诉过程</h3>
                    <el-table border :data="record.contentList" size="mini">
                        <el-table-column label="处理人">
                            <template slot-scope="scope">
                                {{ scope.row.createType === '1' ? '携程' : '900' }}
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column label="处理类型">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                {{ scope.row.createType === '1' ? '携程' : '900' }}-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <el-table-column label="内容">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="info" effect="plain" v-if="scope.row.createType === '1'">
                                    {{
                                        scope.row.appealReason | formatDesc(appealStatusOptions)
                                    }}
                                </el-tag>
                                <div v-html="scope.row.info"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="时间" prop="createTime"></el-table-column>
                        <el-table-column label="附件"></el-table-column>
                    </el-table>
                </div>
                <div class="work-order-detail_r">
                    <ViolationHandling :record="record"
                                       @onChange="handleChange"
                                       v-if="record.appealable===1&&(record.appealStatus==='appealing'||(record.appealStatus==='failed'&&record.maxAppealTime>0))">
                    </ViolationHandling>
                </div>
            </div>
        </div>
    </el-main>
</template>

<script>
import ViolationHandling from "@/www/pages/workOrder/ViolationHandling.vue";
import {getOrderPunishDetailApi} from "@/www/urls/workOrder";
import {
    workOrderStatusOptions,
    workOrderTypeOptions,
    appealStatusOptions,
    documentStatusOptions,
    punishObjectTypeOptions,
    punishOrderStatusOptions,
    punishWayOptions
} from '@/data/workOrder'
import {orderStatusList} from '@/data/index'
import dayjs from "dayjs";

export default {
    name: "WorkOrderDetail",
    data() {
        return {
            visible: false,
            record: {
                others: []
            },
            workOrderStatusOptions,
            workOrderTypeOptions,
            appealStatusOptions,
            documentStatusOptions,
            punishObjectTypeOptions,
            punishOrderStatusOptions,
            punishWayOptions
        }
    },
    components: {
        ViolationHandling
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.loadingShow()
                const ret = await getOrderPunishDetailApi({
                    ctripEventId: this.$route.query.id
                })
                console.log(ret.data);
                if (ret.success) {
                    ret.data.appealReason = JSON.parse(ret.data.appealReason)
                    ret.data.others = [
                        {name: '产品名称', content: ret.data.productName},
                        {name: '用车时间', content: dayjs(ret.data.reserveTime).format('YYYY-MM-DD HH:mm')},
                        // {name: '预估用车时间', content: ''},
                        {
                            name: '订单状态',
                            content: (orderStatusList.find(({value}) => value == ret.data.orderStatus) || {}).name
                        },
                        {name: '订单号', content: ret.data.orderNo},
                        // {name: '供应商单号', content: ''},
                    ]
                    ret.data.punishContentDetail = ret.data.punishContentDetail ? JSON.parse(ret.data.punishContentDetail) : []
                    this.record = ret.data;
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide()
            }
        },
        handleChange() {
            this.getData()
        }
    }
}
</script>

<style scoped lang="scss">
.work-order-detail {
    background-color: #f8f8f8;
    padding: 10px 0;
    display: flex;
    font-size: 14px;

    .work-order-detail_l {
        background-color: #fff;
        padding: 16px;
        border-radius: 8px;
        flex: 1;
        width: auto;

        .l_hd {
            display: flex;
            align-items: center;
        }

        .l_remark {
            background-color: #f8f8f8;
            padding: 10px 16px;
            border-radius: 8px;
            margin: 15px 0;
        }

        h3 {
            margin: 16px 0;
        }
    }

    .work-order-detail_r {
        width: 300px;
        margin-left: 10px;
        background-color: #fff;
    }
}
</style>
