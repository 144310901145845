<template>
    <div class="violation-handling">
        <el-form size="mini" label-position="top" ref="formRef" :model="form">
            <h2 class="mb15">违规处理</h2>
            <el-form-item required prop="appealOperate" :rules="[{required:true,message:'请选择'}]">
                <ApiRadio :options="types" v-model="form.appealOperate"></ApiRadio>
            </el-form-item>
            <el-form-item v-if="form.appealOperate!=='同意处罚'"
                          label="申诉原因"
                          required
                          prop="appealCode"
                          :rules="[{required:true,message:'请输入回复消息'}]">
                <ApiSelect style="width: 100%" :options="reasons"
                           :replace-field="{label:'labelName',value:'labelCode'}" v-model="form.appealCode"></ApiSelect>
            </el-form-item>
            <el-form-item v-if="form.appealOperate!=='同意处罚'">
                <el-input type="textarea" placeholder="补充申诉原因" v-model="form.content"></el-input>
            </el-form-item>
            <el-form-item v-if="form.appealOperate!=='同意处罚'"
                          :required="reason.needAppealCertificate==='true'"
                          :rules="[{required:reason.needAppealCertificate==='true',message:'请上传附件'}]"
                          prop="enclosure">
                <fileUploadMultiple accept="*"
                                    :max-size="20*1024"
                                    :customStyle="true"
                                    v-model="form.enclosure"
                                    @onChange="handleFilesChange">
                    <div style="text-align: left">
                        <el-button size="mini">上传附件</el-button>
                        <div style="font-size: 12px;" class="color-danger"
                             v-if="reason.appealCertificateRequirement||reason.tips">
                            <p>{{ reason.appealCertificateRequirement }}</p>
                            <p>{{ reason.tips }}</p>
                        </div>
                        <p style="font-size: 12px;color: #999">单个文件不得超过20M，可上传多个</p>
                    </div>
                </fileUploadMultiple>
            </el-form-item>
            <el-form-item>
                <el-button size="medium" style="width: 100%" type="primary" :loading="loading" @click="handleSubmit">
                    确认
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {replyOrderEventApi} from "@/www/urls/workOrder";
import fileUploadMultiple from "@/components/fileUploadMultiple/index.vue";

export default {
    name: "ViolationHandling",
    data() {
        return {
            types: [{label: '申诉', value: 'APPEAL'}, {label: '同意惩罚', value: 'CONFIRM'}],
            loading: false,
            form: {
                id: undefined,
                content: undefined,
                appealOperate: undefined,
                appealCode: undefined,
                enclosure: [],
            }
        }
    },
    components: {
        fileUploadMultiple,
    },
    props: {
        record: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        reasons() {
            return this.record.appealReason || []
        },
        reason() {
            return this.reasons.find(({labelCode}) => labelCode === this.form.appealCode) || {}
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.loading = true;
                await this.$refs.formRef.validate()
                const ret = await replyOrderEventApi({
                    ...this.form,
                    appealOperate: this.types.find(({label}) => label === this.form.appealOperate).value,
                    appealReason: this.reasons.find(({labelCode}) => labelCode === this.form.appealCode).labelName,
                    enclosure: this.files && this.files.length ? JSON.stringify(
                        this.files.map((item) => ({
                            fileType: item.name.split('.')[1],
                            fileName: item.name,
                            fileUrl: item.fileUrl,
                        }))) : undefined,
                    id: this.record.eventId
                });
                if (ret.success) {
                    this.$message.success('回复消息提交成功！');
                    this.visible = false;
                    this.$emit('onChange', {
                        id: this.form.id,
                        status: 2
                    })
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loading = false;
            }
        },
        handleFilesChange(files) {
            this.files = files
        }
    }
}
</script>

<style scoped lang="scss">
.violation-handling {
    border-radius: 8px;
    padding: 16px;
}
</style>
